import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import AppContainer from './app/AppContainer';
import DefaultTheme from './themes/DefaultTheme';
import store from './redux/stores/store'

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <StoreProvider store={store}>
    <ThemeProvider theme={DefaultTheme}>
      <CssBaseline />
      <AppContainer />
    </ThemeProvider>
  </StoreProvider>,
);
