import React, { useState } from 'react'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Box, Button, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@mui/material';
import { AccountCircle, Close } from '@mui/icons-material';

const ResetPassword = (props) => {

  const auth = getAuth();

  const [ emailAddress, setEmailAddress ] = useState("");
  const [ isSnackbarOpen, setIsSnackbarOpen ] = useState(false);
  const [ snackbarMessage, setSnackbarMessage ] = useState("");

  const handleError = (error) => {
    const errorCode = error?.code;
    const errorMessage = error?.message;
    console.error("Error Code", errorCode, "Error Message", errorMessage);
    setSnackbarMessage(errorMessage);
    setIsSnackbarOpen(true);
  }

  const handleSubmit = () => {
    sendPasswordResetEmail(auth, emailAddress)
    .then(() => {
      setSnackbarMessage("Password reset email sent to " + emailAddress)
      setIsSnackbarOpen(true);
      setEmailAddress("");
    })
    .catch((error) => {
      handleError(error);
      setEmailAddress("");
    });
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box sx={{alignItems: "center", display: "flex", flex: 1, justifyContent: "center",}}>
      <Box sx={{display: "flex", flexDirection: "column",}}>
        <Typography
          variant="h1"
          fontSize={36}
          fontWeight={600}
          textAlign="left"
        >
          Forgot Password
        </Typography>
        <Box sx={{alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", my: 2, }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle color="secondary" />
                </InputAdornment>
              ),
            }}
            label="Email Address"
            onChange={(e) => setEmailAddress(e.target.value)}
            sx={{my: .5, outlineColor: "secondary", width: 300,}}
            value={emailAddress}
          />
          <Button
            onClick={handleSubmit}
            sx={{my: .5, width: 300,}}
            variant="contained"
          >
            Reset Password
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={action}
      />
    </Box>
  )
}

export default ResetPassword