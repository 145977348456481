import React from 'react'
import { Box, Container,} from '@mui/material'
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const Template = (props) => {

  return (
    <Box>
      <Header />
      <Box sx={{my: 2.5,}}></Box>
      <Container maxWidth="xl" sx={{display: "flex", flex: 1,}}>
        {props.children}
      </Container>
      <Box sx={{my: 2.5,}}></Box>
      <Footer />
    </Box>
  )
}

export default Template;