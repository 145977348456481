import { AccountCircle, Logout, Settings } from '@mui/icons-material'
import { AppBar, Box, IconButton, TextField, Toolbar, Typography } from '@mui/material'
import { getAuth, signOut } from 'firebase/auth'
import React, { useState } from 'react'
import AccountSettings from '../submodules/AccountSettings'
import { useSelector } from 'react-redux'
import AdminSettings from '../submodules/AdminSettings'

const Header = (props) => {

  const auth = getAuth();

  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [isAdminSettingsModalVisible, setIsAdminSettingsModalVisible] = useState(false);
  const isAdmin = useSelector(state => state.app.isAdmin);
  
  const handleSignOut = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
      console.error(error);
    });
  }

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Box component="img" src={require("../../assets/png/sto-envisioneer-logo.png")} sx={{width: 75,}}></Box>
        <Typography color="#3e3639" fontSize={16} fontWeight={600} lineHeight={1} sx={{mx: 1}} variant="h6">Panel Designer</Typography>
        <Typography color="#ffffff" fontSize={12} sx={{backgroundColor: "#d12f36", borderRadius: 1, py:.25, px:.75,}}>Development Builds</Typography>
        <Box sx={{flexGrow: 1,}}></Box>
        
        {
          auth.currentUser !== null ? (
            <React.Fragment>
              {
                isAdmin ? (
                  <IconButton onClick={() => {setIsAdminSettingsModalVisible(true)}}>
                    <Settings />
                  </IconButton>
                ) : null
              }
              <IconButton onClick={() => {setIsAccountModalVisible(true)}}>
                <AccountCircle />
              </IconButton>
              <IconButton onClick={handleSignOut}>
                <Logout />
              </IconButton>
            </React.Fragment>
          ) : null
        }
      </Toolbar>
      { isAccountModalVisible ?
        <AccountSettings
          isAccountModalVisible={isAccountModalVisible}
          setIsAccountModalVisible={setIsAccountModalVisible}
        /> : null 
      }
      { isAdminSettingsModalVisible ?
        <AdminSettings
          isAdminSettingsModalVisible={isAdminSettingsModalVisible}
          setIsAdminSettingsModalVisible={setIsAdminSettingsModalVisible}
        /> : null 
      }
    </AppBar>
  )
}

export default Header