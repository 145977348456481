import axios from 'axios'

// Reusable function to send email via PHP script
export const sendEmailWithSES = async (emailRecipient, emailSubject, emailBody) => {
  const url = "https://wp-api.cadsoft.com/ses-send-email.php";

  // Create a new URLSearchParams object to encode the data as x-www-form-urlencoded
  const params = new URLSearchParams();
  params.append('emailRecipient', emailRecipient);
  params.append('emailSubject', emailSubject);
  params.append('emailBody', emailBody);

  return axios.post(url, params)
    .then((response) => {
      console.log('Response:', response.data);
      return response.data; // You can return the response data if needed
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};