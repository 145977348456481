import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const PasswordResetButton = (props) => {
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [error, setError] = useState(null);
  const auth = getAuth();

  console.log("User Email Address:", props?.user?.emailAddress);

  const handleResetPassword = () => {
    setError(null);
    sendPasswordResetEmail(auth, props?.user?.emailAddress)
      .then(() => {
        setResetEmailSent(true);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div>
      {resetEmailSent ? (
        <Typography variant="body1">Password reset email sent. Have user check their inbox.</Typography>
      ) : (
        <>
          <Button variant="contained" color="primary" onClick={handleResetPassword}>
            Send Password Reset
          </Button>
          {error && <Typography variant="body1" color="error">{error}</Typography>}
        </>
      )}
    </div>
  );
};

export default PasswordResetButton;
