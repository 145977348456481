// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { initializeFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCcrsGvH-29Ae5gDFO6z7Js6Ny3Z5aJejo",
  authDomain: "sto-beta-project.firebaseapp.com",
  projectId: "sto-beta-project",
  storageBucket: "sto-beta-project.appspot.com",
  messagingSenderId: "601904141400",
  appId: "1:601904141400:web:edab65c03a530c6fe9a62d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log("App initialized.", app);
const auth = getAuth(app);
console.log("Authentication initialized.", auth);
if (auth.currentUser) {
  console.log("Current User ID:", getAuth().currentUser.uid);
}
export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});