import { Box } from '@mui/material'
import React from 'react'
import Sidebar from '../submodules/Sidebar';
import ReadingPane from '../submodules/ReadingPane';
import Feedback from '../submodules/Feedback';
import { useSelector } from 'react-redux';

import EmailVertification from '../submodules/EmailVertification';

const LearningCenter = (props) => {

  const isEmailVerified = useSelector(state => state.app.isEmailVerified);

  if (!isEmailVerified) {
    return (
      <EmailVertification />
    )
  } else return (
    <Box sx={{display: "flex", flexWrap: "wrap",}}>
      <Box sx={{display: "flex", flex: 1, flexWrap: "wrap", flexDirection: "column", margin: 1, minWidth: 200,}}>
        <Sidebar />
      </Box>
      <Box sx={{display: "flex", flex: 4, flexWrap: "wrap", flexDirection: "column", margin: 1,}}>
        <ReadingPane />
        <Feedback />
      </Box>
    </Box>
  )
}

export default LearningCenter