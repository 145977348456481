import { Close, Lock } from '@mui/icons-material'
import { Box, Button, Card, Divider, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material'
import { getAuth, updatePassword } from 'firebase/auth';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import ReactGA from "react-ga4";

const AccountSettings = (props) => {

  const isAccountModalVisible = props.isAccountModalVisible;
  const setIsAccountModalVisible = props.setIsAccountModalVisible;
  const firstName = useSelector(state => state.app.firstName);
  const lastName = useSelector(state => state.app.lastName);
  const [newPassword, setNewPassword] = useState("");

  const handleSettingNewPassword = (e) => {
    setNewPassword(e.target.value);
  }

  const handleSubmitNewPassword = () => {
    updatePassword(user, newPassword).then(() => {
      // Update successful.
      setNewPassword("");
      ReactGA.event({
        category: "Beta",
        action: "Change Password",
        label: firstName + " " + lastName + " set a new password.", // optional
      });
    }).catch((error) => {
      console.error(error);
    });
  }

  return (
    <Modal
      open={isAccountModalVisible}
      onClose={() => {setIsAccountModalVisible(false)}}
    >
      <Box sx={{alignItems: "center", display: "flex", flex: 1, flexGrow: 1, justifyContent: "center",}}>
        <Card sx={{m: 10, width: 350,}}>
          <Box sx={{display: "flex", flex: 1, justifyContent: "end"}}>
            <IconButton onClick={() => setIsAccountModalVisible(false)}>
              <Close />
            </IconButton>
          </Box>
          <Box sx={{px: 2, pb: 2,}}>
            <Typography variant="h2" fontSize={24} fontWeight={600}>
              Account Settings
            </Typography>
            <Divider sx={{marginY: 1,}} />
            <Typography variant="h3" fontSize={18} sx={{my: 1,}}>{firstName + " " + lastName}</Typography>
            <Typography variant="h4" fontSize={16} sx={{my: 1,}}>{getAuth().currentUser.email}</Typography>
            <Box sx={{display: "flex", flexDirection: "column", my: 1,}}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="secondary" />
                    </InputAdornment>
                  ),
                }}
                label='New Password'
                onChange={handleSettingNewPassword}
                placeholder="New Password"
                value={newPassword}
                sx={{my: 1,}}
              />
              <Button variant="contained" onClick={handleSubmitNewPassword} sx={{my: .25,}}>Submit</Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </Modal>
  )
}

export default AccountSettings