import { Close, Publish } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Paper, Snackbar, TextField, Typography } from '@mui/material'
import { getAuth } from 'firebase/auth';
import React, { useState } from 'react'
import { postIssue } from '../../api/JiraAPI';
import { useSelector } from 'react-redux';
import ReactGA from "react-ga4";

const Feedback = (props) => {

  // hooks.
  const auth = getAuth();
  const firstName = useSelector(state => state.app.firstName);
  const lastName = useSelector(state => state.app.lastName);
  const currentlyReading = useSelector(state => state.app.currentlyReading)
  const posts = useSelector(state => state.app.posts);

  // state.
  const [ feedbackSummary, setFeedbackSummary ] = useState("");
  const [ feedbackDescription, setFeedbackDescription ] = useState("");
  const [ isSnackbarOpen, setIsSnackbarOpen ] = useState(false);
  const [ snackbarMessage, setSnackbarMessage ] = useState("");
  const [ isLoading, setIsLoading ] = useState(false);

  // called when feedback form is submitted.
  const handleSubmit = async () => {

    setIsLoading(true);
    const currentPost = posts.find(post => post.id === currentlyReading);
    const issueSummary = currentPost?.title?.rendered + " > " + feedbackSummary;
    const issueDescription = feedbackDescription + 
      "\n Submitted by User ID: " + auth.currentUser.uid + 
      "\n Email Address: " + auth.currentUser.email +
      "\n Name: " + firstName + " " + lastName;
    try {
      const successfulFeedback = await postIssue(issueSummary, issueDescription);
      if (successfulFeedback !== "Error") {
        setFeedbackSummary("");
        setFeedbackDescription("");
        setSnackbarMessage("Feedback successfully sent.");
        setIsSnackbarOpen(true);
        setIsLoading(false);
        // sends ga event.
        ReactGA.event({
          category: "Beta",
          action: "Feedback",
          label: firstName + " " + lastName + " sent feedback for: " + currentPost?.title.rendered, // optional
        });
      } else {
        setSnackbarMessage("There was an error submitting feedback.");
        setIsSnackbarOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage(error.message);
      setIsSnackbarOpen(true);
      setIsLoading(false);
    }
  }

  // handle snackbar.
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarOpen(false);
  };

  // renders in snackbar.
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Paper sx={{my: 5, p: 2.5,}}>
      <Typography component="h3" fontWeight={600} variant="h4">Feedback</Typography>
      <TextField
        fullWidth
        multiline
        onChange={(e) => setFeedbackSummary(e.target.value)}
        placeholder="Short feedback summary."
        sx={{my: 1,}}
        value={feedbackSummary}
      />
      <TextField
        fullWidth
        multiline
        onChange={(e) => setFeedbackDescription(e.target.value)}
        rows={5}
        placeholder='Write your feedback about this lesson.'
        sx={{my: 1,}}
        value={feedbackDescription}
      />
      <Button
        disabled={isLoading}
        endIcon={isLoading ? <CircularProgress size={20} /> : <Publish />}
        fullWidth
        onClick={handleSubmit}
        variant="contained"
      >
        Submit Feedback
      </Button>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={action}
      />
    </Paper>
  )
}

export default Feedback