import React, { useState } from 'react'
import { Button } from '@mui/material'
import { doc, updateDoc, } from 'firebase/firestore';
import { sendEmailWithSES } from '../../api/sesAPI'
import { db } from '../../config/firebase.config';

const VerifyEmailButton = (props) => {

  const [ isDisabled, setIsDisabled ] = useState(false);

  const handleEmailSend = (user) => {
    const emailRecipient = user.emailAddress;
    const emailSubject = "sto.cadsoft.com: User Approval";
    const emailBody =  "Your account on sto.cadsoft.com has been approved. You can now sign in."
    sendEmailWithSES(emailRecipient, emailSubject, emailBody);
    setIsDisabled(true);
  }

  const handleVerifyEmail = async (user) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      console.log("userRef:", userRef);
      await updateDoc(userRef, { emailVerified: true });

      handleEmailSend(user);
    } catch (error) {
      console.error('Error verifying email:', error);
    }
  };

  return (
    <Button color="secondary" disabled={isDisabled} variant="contained" onClick={() => { handleVerifyEmail(props?.user) }}>Approve User</Button>
  )
}

export default VerifyEmailButton