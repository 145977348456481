import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Dialog, IconButton, Typography,} from '@mui/material'
import { collection, getDoc, getDocs, updateDoc, } from 'firebase/firestore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import UserProfileInformation from './UserProfileInformation';
import { db } from '../../config/firebase.config'

const AdminSettings = (props) => {


  const isAdminSettingsModalVisible = props.isAdminSettingsModalVisible;
  const setIsAdminSettingsModalVisible = props.setIsAdminSettingsModalVisible;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch the 'users' collection from Firebase Firestore
    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, 'users');
        const snapshot = await getDocs(usersRef);
        const usersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // sorts users by first name.
        const sortedArray = usersData.slice().sort((a, b) => {
          if (a.firstName < b.firstName) {
            return -1;
          }
          if (a.firstName > b.firstName) {
            return 1;
          }
          return 0;
        });

        // pushes sorted users to state.
        setUsers(sortedArray);
      } catch (error) { console.error('Error fetching users:', error); }
    };

    fetchUsers();
  }, []);


  // handles user approval.
  const handleUserApproval = async (uid) => {
    try {
      const userRef = doc(db, 'users', uid); // 'users' is your Firestore collection name
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists()) {
        // Update the emailVerified field to true
        await updateDoc(userRef, {
          emailVerified: true,
        });
  
        console.log(`User with UID ${uid} has been approved.`);
      } else {
        console.error(`User with UID ${uid} not found.`);
      }
    } catch (error) {
      console.error('Error updating user emailVerified:', error);
    }
  };

  
  



  // New Return Statement
  return (
    <Dialog
      open={isAdminSettingsModalVisible}
      fullScreen
      onClose={() => {setIsAdminSettingsModalVisible(false)}}
    >
      {
        users.length > 0 ? (
          <Box sx={{overflowY: "auto",}}>
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "flex-end", m:1,}}>
              <IconButton onClick={() => {setIsAdminSettingsModalVisible(false)}}>
                <CloseIcon />
              </IconButton>
            </Box>
            {
              users.map(user => (
                <Accordion key={user.uid}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${user.uid} + "-content"`} id={`${user.uid} + "-header"`}>
                    <Box sx={{alignItems: "center", display: "flex", flex: 1,}}>
                      <Box sx={{alignItems: "center", display: "flex", justifyContent: "flex-start", flexDirection: "row"}}>
                        { user?.emailVerified ? ( <CheckIcon color="primary" /> ) : ( <CloseIcon color="secondary" /> )}
                        <AccountCircleOutlinedIcon sx={{ml: 1,}}/>
                        <Typography component="h2" sx={{ml: 1,}} variant="h6">{user.firstName + " " + user.lastName}</Typography>
                        <Typography fontSize={10} sx={{ml: 1,}}>{user.emailAddress}</Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <UserProfileInformation user={user} />
                  </AccordionDetails>
                </Accordion>
              ))
            }
          </Box>
        ) : (
          <Box sx={{alignItems: "center", display: "flex", flex: 1, justifyContent: "center",}}>
            <CircularProgress />
          </Box>
        )
      }
    </Dialog>
  );
}





export default AdminSettings;