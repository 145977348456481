import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import BookIcon from '@mui/icons-material/Book'
import ReactHTMLParser from 'react-html-parser'
import PasswordResetButton from './PasswordResetButton';
import VerifyEmailButton from './VerifyEmailButton';

const UserProfileInformation = (props) => {

  const posts = useSelector(state => state.app.posts);
  const currentlyReading = posts.find(post => post.id === props.user.currentlyReading);


  return (
    <Box>
      <Box sx={{display: "flex",}}>
        { props?.user?.emailVerified ? null : ( <VerifyEmailButton user={props?.user} /> )}
        <Box sx={{ml: 1,}}>
          <PasswordResetButton user={props?.user} />
        </Box>
      </Box>
      <List>
        <ListItem disablePadding>
          <ListItemIcon><Grid3x3Icon /></ListItemIcon>
          <ListItemText primary="User ID" secondary={props?.user?.uid} />
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon><BookIcon /></ListItemIcon>
          <ListItemText primary={"Currently Reading"} secondary={currentlyReading?.title?.rendered} />
        </ListItem>
        {
          props?.user?.read.length > 0 ? (
            <Box sx={{p:1,}}>
              <Divider />
              <Typography component="h3" fontSize={12} fontWeight={500} sx={{mt: 2,}}>Marked as Read</Typography>
              <Box sx={{display: "flex", flexDirection: "column",}}>
                {
                  props?.user?.read.map(postId => (
                    <PostTitle key={postId} postId={postId} />
                  ))
                }
              </Box>
            </Box>
          ) : null
        }
      </List>
    </Box>
  )
}

export default UserProfileInformation

const PostTitle = (props) => {

  const postId = props?.postId;
  const posts = useSelector(state => state.app.posts);

  const foundPost = posts.find(post => post.id === postId)
  
  return (
    <Typography fontSize={10}>{ReactHTMLParser(foundPost?.title.rendered)}</Typography>
  )
}