import React, { useState } from 'react'
import SignInOrRegister from '../submodules/SignInOrRegister'
import ResetPassword from '../submodules/ResetPassword';
import { Box, Button } from '@mui/material';

const Authentication = (props) => {

  const [forgotPassword, setForgotPassword] = useState(false);
  return (
    <Box sx={{alignItems: "center", display: "flex", flex: 1, flexDirection: "column", justifyContent: "center",}}>
      {!forgotPassword ? <SignInOrRegister /> : <ResetPassword />}
      <Button
        color="secondary"
        onClick={() => setForgotPassword(!forgotPassword)}>{forgotPassword ? "Back to Sign In" : "Reset Password" }
      </Button>
    </Box>
  )

}

export default Authentication