import axios from 'axios'

export const postIssue = async (issueSummary, issueDescription) => {
  const summary = issueSummary.toString();
  console.log("Summary:", summary);
  const description = issueDescription.toString();
  console.log("Description:", description);

  // Prepare the data to be sent
  const data = JSON.stringify({
    summary: summary,
    description: description
  });
  // Send a POST request to the PHP server
  axios.post(process.env.REACT_APP_JIRA_API_URL, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      // Handle the response from the PHP server
      const issue = response.data;
      // Do something with the received issue
      console.log(issue);
      return issue;
    })
    .catch(error => {
      // Handle errors that occurred during the request
      console.error(error);
      return "Error";
    });
}