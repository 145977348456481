import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateRead } from '../../redux/slices/appSlice';
import { Box, Button, Divider, IconButton, Paper, Skeleton, Typography } from '@mui/material';
import ReactHTMLParser from 'react-html-parser'
import { AddTask, Beenhere, CloudDownload } from '@mui/icons-material';
import ReactGA from "react-ga4";
import { db } from '../../config/firebase.config';

const ReadingPane = () => {

  const dispatch = useDispatch();
  const currentlyReading = useSelector(state => state.app.currentlyReading);
  const posts = useSelector(state => state.app.posts);
  const read = useSelector(state => state.app.read);
  const firstName = useSelector(state => state.app.firstName);
  const lastName = useSelector(state => state.app.lastName);

  const [ currentPost, setCurrentPost ] = useState(null);

  // changes visible post when global state changes.
  useEffect(() => {
    setCurrentPost(posts.find(post => post.id === currentlyReading))
    return () => {setCurrentPost(null)}
  }, [currentlyReading]);

  // fetches user record in db, updates read array with current post id, then updates global state.
  const handleMarkAsRead = async (id) => {
    try {
      const docRef = doc(db, "users", getAuth().currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const duplicatedArray = [].concat(data.read).concat(id).sort((a, b) => a-b);
        const filteredArray = [... new Set(duplicatedArray)]
        await updateDoc(docRef, {
          read: filteredArray,
        })
        dispatch(updateRead(filteredArray));
        // sends ga event.
        ReactGA.event({
          category: "Beta",
          action: "Reading",
          label: firstName + " " + lastName + " read: " + currentPost?.title.rendered, // optional
        });
      } else {
        // docSnap.data() will be undefined in this case
        console.error("No such document!");
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Paper sx={{p: 2.5,}}>
      <Box sx={{display: "flex"}}>
        {
          currentPost ?
            (
              <Typography component="h1" variant="h2" fontWeight={600} sx={{display: "flex", flex: 1,}}>
                { ReactHTMLParser(currentPost?.title?.rendered) }
              </Typography>
            ) :
            <Skeleton variant="rounded" height={72} width="100%" />
        }
        <Box>
          <IconButton
            color={read.includes(currentPost?.id) ? "success" : "tertiary"}
            disabled={!currentPost}
            onClick={() => handleMarkAsRead(currentPost?.id)}
            height={25}
            width={25}
          >
            {read.includes(currentPost?.id) ? <Beenhere /> : <AddTask />}
          </IconButton>
        </Box>
      </Box>
      {
        currentPost ?
          (
            <Typography>
              {currentPost?.acf?.short_description}
            </Typography>
          ) :
          <Skeleton fontSize={24}  />
      }
      {
        currentPost?.acf?.download_uri ? (
          <Button
            color="secondary"
            endIcon={<CloudDownload />}
            href={currentPost?.acf?.download_uri}
            sx={{my: 1, width: 300,}}
            variant="contained"
            disabled={!currentPost}
          >
            { currentPost ? "Download Installer" : <Skeleton fontSize={16.5} width="100%" />}
          </Button>
        ) : null
      }
      <Divider />
      {
        currentPost?.acf?.video_uri ? (
          <React.Fragment>
            {
              currentPost ? (
                <Box
                  component="iframe"
                  src={currentPost?.acf?.video_uri}
                  sx={{border: 0, borderRadius: 1, height: 400, marginY: 1, width: "100%"}}
                ></Box>
              ) : <Skeleton variant="rounded" height={400} width="100%" sx={{marginY: 1}} />
            }
          </React.Fragment>
        ) : null
      }
      <Divider />
      <Box component="div">
        {ReactHTMLParser(currentPost?.content?.rendered)}
      </Box>
      <Divider sx={{my: 1,}} />
      <Button
        color={read.includes(currentPost?.id) ? "success" : "secondary"}
        disabled={!currentPost}
        endIcon={read.includes(currentPost?.id) ? <Beenhere /> : <AddTask />}
        fullWidth
        onClick={() => handleMarkAsRead(currentPost?.id)}
        variant="contained"
      >
        { currentPost ? 
          (read.includes(currentPost?.id) ? "You've Read This Post" : "Mark as Read") : 
          <Skeleton fontSize={16.5} width="100%" />
        }
      </Button>
    </Paper>
  )
}

export default ReadingPane