import axios from 'axios';

const WordPressAPI = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_CMS_URL : process.env.REACT_APP_PROD_CMS_URL,
})

export const getCategoryPosts = async (categoryId) => {
  const posts = await WordPressAPI.get("/posts", {
    params: {
      categories: categoryId,
      per_page: 90,
    }
  })
  .then(data => {
    console.log(data);
    return data;
  })
  return posts;
}