import '../config/firebase.config'
import React, { useEffect, useState, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { Box, CircularProgress, Typography } from '@mui/material'
import { getCategoryPosts } from '../api/WordPressAPI'
import Authentication from '../components/modules/Authentication'
import LearningCenter from '../components/modules/LearningCenter'
import Template from '../components/templates/Template'
import {
  updateCurrentlyReading,
  updateFirstName,
  updateHelp,
  updateIsEmailVerified,
  updateIsAdmin,
  updateIsAuthenticated,
  updateLastName,
  updatePosts,
  updateRead,
  updateDownloads,
} from '../redux/slices/appSlice'
import { doc, getDoc, } from 'firebase/firestore'
import ReactGA from "react-ga4";
import { db } from '../config/firebase.config'

const AppContainer = (props) => {

  // hooks.
  const auth = getAuth();
  const dispatch = useDispatch();

  // state.
  const isAuthenticated = useSelector(state => state.app.isAuthenticated)
  const posts = useSelector(state => state.app.posts);
  const firstName = useSelector(state => state.app.firstName);
  const lastName = useSelector(state => state.app.lastName);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ errorMessage, setErrorMessage ] = useState("");



  // initialize ga4 analytics.
  useEffect(() => {
    ReactGA.initialize("G-GG7QZ5M49C");
    return () => {console.log("Clean up for initializing GA4 property: G-GG7QZ5M49C");};
  }, [])

  // when auth changes, check if there's still a user and authenticate.
  useEffect(() => {
    const fetchUserRecord = async () => {
      try {
        const docRef = doc(db, "users", getAuth().currentUser.uid)
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data()
          dispatch(updateCurrentlyReading(data.currentlyReading));
          dispatch(updateFirstName(data.firstName));
          dispatch(updateIsAdmin(data.isAdmin));
          dispatch(updateIsAuthenticated(true));
          dispatch(updateIsEmailVerified(data.emailVerified));
          dispatch(updateLastName(data.lastName));
          dispatch(updateRead(data.read));
          // sends ga event.
          ReactGA.event({
            category: "Beta",
            action: "Login",
            label: firstName + " " + lastName + " logged in.", // optional
          });
          console.log("Document data:", data);
        } else {
          // docSnap.data() will be undefined in this case
          console.error("No such document!");
        }
      } catch (error) {
        console.error(error);
      }
    }

    // clears user data from global state.
    const clearUserRecord = () => {
      dispatch(updateIsAuthenticated(false));
      dispatch(updateCurrentlyReading(null));
      dispatch(updateFirstName(""));
      dispatch(updateIsAdmin(false));
      dispatch(updateLastName(""));
      dispatch(updateRead([]));
    }

    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserRecord();
      } else {
        clearUserRecord();
      }
    });
  }, [auth]);

  // Fetches posts from WordPress under Category with ID 3, "STO Tutorials".
  useEffect(() => {
    getCategoryPosts(3)
    .then(res => {
      const data = res.data.sort((a, b) => a?.date - b?.date).reverse();
      console.log("Posts:", data);
      dispatch(updatePosts(data));
      setIsLoading(false);
    })
    .catch(err => {
      console.error(err);
      setErrorMessage(err.message);
      setIsLoading(false);
    });

    return () => {
      dispatch(updatePosts([]));
      setErrorMessage("");
      console.log("Clean up for getting posts under category 3.");
    }
  }, []);


    // Fetches posts from WordPress under Category with ID 4, "STO Downloads".
    useEffect(() => {
      getCategoryPosts(4)
      .then(res => {
        const data = res.data.sort((a, b) => a?.date - b?.date).reverse();
        console.log("Downloads:", data);
        dispatch(updateDownloads(data));
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
        setErrorMessage(err.message);
        setIsLoading(false);
      });
  
      return () => {
        dispatch(updateDownloads([]));
        setErrorMessage("");
        console.log("Clean up for getting posts under category 4.");
      }
    }, []);

    // Fetches posts from WordPress under Category with ID 5, "STO Help".
    useEffect(() => {
      getCategoryPosts(5)
      .then(res => {
        const data = res.data.sort((a, b) => a?.date - b?.date).reverse();
        console.log("Help:", data);
        dispatch(updateHelp(data));
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
        setErrorMessage(err.message);
        setIsLoading(false);
      });

      return () => {
        dispatch(updateHelp([]));
        setErrorMessage("");
        console.log("Clean up for getting posts under category 5.");
      }
    }, []);

  return (
    <Template>
      <Box sx={{display: "flex", flex: 1, flexDirection: "column", flexGrow: 1,}}>
        { posts.length > 0 ? ( 
          isAuthenticated ? 
            <LearningCenter /> :
            <Authentication />
        ) : (
          <Box sx={{ alignItems: "center", display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", }}>
            { isLoading ? 
              <CircularProgress color="secondary" /> : (
              <Typography>{errorMessage}</Typography>
            )}
          </Box>
        )}
      </Box>
    </Template>
  )
}

export default AppContainer
