import { ArrowDownward, AutoStories, Close, CloudDownload, Download, PlayLesson, QuestionAnswer } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Paper, Typography } from '@mui/material'
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentlyReading } from '../../redux/slices/appSlice';
import ReactGA from 'react-ga4';
import ReactHTMLParser from 'react-html-parser'
import { db } from '../../config/firebase.config';

const Sidebar = () => {

  // hooks
  const dispatch = useDispatch();

  // global states.
  const currentlyReading = useSelector(state => state.app.currentlyReading);
  const downloads = useSelector(state => state.app.downloads);
  const help = useSelector(state => state.app.help);
  const read = useSelector(state => state.app.read);
  const posts = useSelector(state => state.app.posts);
  const firstName = useSelector(state => state.app.firstName);
  const lastName = useSelector(state => state.app.lastName);

  // local states.
  const [ isDownloadsModalVisible, setIsDownloadsModalVisible ] = useState(false);
  const [ isHelpModalVisible, setIsHelpModalVisible ] = useState(false);
  const handleDownloadsModalOpen = () => setIsDownloadsModalVisible(true);
  const handleHelpModalOpen = () => setIsHelpModalVisible(true);
  const handleDownloadsModalClose = () => setIsDownloadsModalVisible(false);
  const handleHelpModalClose = () => setIsHelpModalVisible(false);


  const helpSeparator = '<hr class="wp-block-separator has-alpha-channel-opacity"/>';

  const renderHelpAccordions = (post) => {
    const topics = post?.content?.rendered.split(helpSeparator);

    return topics.map((topic, topicIndex) => {
      const [summaryContent, detailsContent] = topic.split('</h2>');

      return (
        <Accordion key={topicIndex}>
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls={`panel-content-${topicIndex}`}
            id={`panel-header-${topicIndex}`}
          >
            {ReactHTMLParser(summaryContent)}
          </AccordionSummary>
          <AccordionDetails>
            {ReactHTMLParser(detailsContent)}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  // updates currenlty reading id in db then in global state if successful.
  const pushNewCurrentlyReading = async (id) => {
    try {
      const docRef = doc(db, "users", getAuth().currentUser.uid);
      await updateDoc(docRef, {
        currentlyReading: id,
      });
      dispatch(updateCurrentlyReading(id));
      // sends ga event.
      const currentPost = posts.find(post => post.id === id);
      ReactGA.event({
        category: "Beta",
        action: "Reading",
        label: firstName + " " + lastName + " started reading: " + currentPost?.title.rendered, // optional
      });
    } catch (error) {
      console.error(error)
    }
  }

  const determineReadColor = (id) => {
    if (read.includes(id)) {
      return "success"
    } else if (id === currentlyReading) {
      return "primary"
    } else return "secondary"
  }

  return (
    <Paper>
      <List>
        <ListItem>
          <ListItemText>
            <Typography fontWeight={600} variant="h6">Downloads</Typography>
          </ListItemText>
        </ListItem>
        {
          downloads.map(download => (
            <Box key={download.id}>
              <ListItemButton
                onClick={() => { handleDownloadsModalOpen() }}
              >
                <ListItemIcon>
                  <Download />
                </ListItemIcon>
                <ListItemText>{ ReactHTMLParser(download.title.rendered) }</ListItemText>
              </ListItemButton>
              <Modal
                open={isDownloadsModalVisible}
                onClose={handleDownloadsModalClose}
                aria-labelledby="modal-downloads"
                aria-describedby="modal-downloads-description"
              >
                <Box>
                  <Paper sx={{padding: 2,}}>
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                      <Typography fontWeight={600} id="modal-downloads-heading" variant="h5" component="h2">
                        {ReactHTMLParser(download?.title?.rendered)}
                      </Typography>
                      <IconButton onClick={() => { handleDownloadsModalClose() }}>
                        <Close />
                      </IconButton>
                    </Box>
                    <Box sx={{alignItems: "center", display: "flex",}}>
                      <Button
                        color="secondary"
                        endIcon={<CloudDownload />}
                        href={download?.acf?.download_uri}
                        onClick={async () => {
                          console.log("Downloaded!");
                          try {
                            const docRef = doc(db, "users", getAuth().currentUser.uid);
                            await updateDoc(docRef, {
                              lastDownloaded: download?.acf?.build,
                            })
                            ReactGA.event({
                              category: "file_download",
                              action: "file_download",
                              label: firstName + " " + lastName + " downloaded: " + download?.acf?.build, // optional
                            });
                          }
                          catch (error) {
                            console.error(error)
                          }
                        }}
                        sx={{my: 1, width: 300,}}
                        variant="contained"
                      >
                        { download ? "Download Installer" : <Skeleton fontSize={16.5} width="100%" />}
                      </Button>
                      <Typography fontWeight={500} variant="h6" sx={{marginLeft: 2,}}>{ download?.acf?.build }</Typography>
                    </Box>
                    {ReactHTMLParser(download?.content?.rendered)}
                  </Paper>
                </Box>
              </Modal>
            </Box>
          ))
        }
        <Divider />
        <ListItem>
          <ListItemText>
            <Typography fontWeight={600} variant="h6">Help</Typography>
          </ListItemText>
        </ListItem>
        {
          help.map(post => (
            <Box key={post.id}>
              <ListItemButton onClick={() => handleHelpModalOpen()}>
                <ListItemIcon>
                  <QuestionAnswer />
                </ListItemIcon>
                <ListItemText>{ReactHTMLParser(post.title.rendered)}</ListItemText>
              </ListItemButton>
              <Modal
                open={isHelpModalVisible}
                onClose={handleHelpModalClose}
                aria-labelledby="modal-help"
                aria-describedby="modal-help-description"
                sx={{
                  position:'absolute',
                  overflow:'scroll',
                  height:'100%',
                  display:'block'
                }}
              >
                <Box>
                  <Paper sx={{p: 2, m:2,}}>
                    <Box sx={{display: "flex", flexDirection: "row-reverse"}}>
                      <IconButton size="small" onClick={() => handleHelpModalClose()}>
                        <Close />
                      </IconButton>
                    </Box>
                    <Typography variant="h4" fontWeight={600} sx={{mb:2,}}>{ReactHTMLParser(post.title.rendered)}</Typography>
                    {/* Rendering the Accordions for this help topic */}
                    {renderHelpAccordions(post)}
                  </Paper>
                </Box>
              </Modal>
            </Box>
          ))
        }
        <Divider />
        <ListItem>
          <ListItemText>
            <Typography fontWeight={600} variant="h6">Lessons</Typography>
          </ListItemText>
        </ListItem>
        <Divider />
        {
          posts.map(post => (
            <ListItemButton
              key={post.id}
              onClick={() => pushNewCurrentlyReading(post.id)}
            >
              <ListItemIcon>
                {
                  post.id === currentlyReading ?
                  <AutoStories color={determineReadColor(post.id)} />  : 
                  <PlayLesson color={determineReadColor(post.id)} />
                }
              </ListItemIcon>
              <ListItemText>{ ReactHTMLParser(post.title.rendered) }</ListItemText>
            </ListItemButton>
          ))
        }
      </List>
    </Paper>
  )
}

export default Sidebar