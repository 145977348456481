import { AccountCircle, Close, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@mui/material';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, serverTimestamp, } from 'firebase/firestore';
import React, { useState } from 'react'
import { db } from '../../config/firebase.config'

const SignInOrRegister = (props) => {

  const auth = getAuth();

  const [ confirmEmailAddress, setConfirmEmailAddress ] = useState("");
  const [ confirmPassword, setConfirmPassword ] = useState("");
  const [ emailAddress, setEmailAddress ] = useState("");
  const [ firstName, setFirstName ] = useState("");
  const [ isSnackbarOpen, setIsSnackbarOpen ] = useState(false);
  const [ isRegister, setIsRegister ] = useState(false);
  const [ lastName, setLastName ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ showPassword, setShowPassword ] = useState(false);
  const [ snackbarMessage, setSnackbarMessage ] = useState("");

  // runs after a user signs in to clear forms.
  const resetLocalState = () => {
    setConfirmEmailAddress("");
    setConfirmPassword("")
    setEmailAddress("");
    setIsRegister(false);
    setIsSnackbarOpen(false);
    setShowPassword(false);
    setPassword("");
  }

  // handles error messages.
  const handleError = (error) => {
    const errorCode = error?.code;
    const errorMessage = error?.message;
    console.error("Error Code", errorCode, "Error Message", errorMessage);
    setSnackbarMessage(errorMessage);
    setIsSnackbarOpen(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isRegister) {
      if (emailAddress === confirmEmailAddress && password === confirmPassword) {
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, emailAddress, password)
          // Get the user ID
          const uid = userCredential.user.uid;
          // Add user ID to Firestore
          await setDoc(doc(db, 'users', uid), {
            accountCreated: serverTimestamp(),
            currentlyReading: 54,
            emailAddress: emailAddress,
            emailVerified: false,
            firstName: firstName,
            isAdmin: false,
            lastName: lastName,
            read: [],
            uid: uid,
          });
        } catch (error) {
          handleError(error);
        }
      } else {
        const error = {
          code: "Manual error: not matching.",
          message: "Email or password does not match.",
        };
        handleError(error);
      }
    } else {
      signInWithEmailAndPassword(auth, emailAddress, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
        resetLocalState();
      })
      .catch((error) => {
        handleError(error);
      });
    }
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box component="form" sx={{alignItems: "center", display: "flex", flex: 1, justifyContent: "center",}}>
      <Box sx={{display: "flex", flexDirection: "column",}}>
        <Typography
          variant="h1"
          fontSize={36}
          fontWeight={600}
          textAlign="left"
        >
          { !isRegister ? "Sign In" : "Register" }
        </Typography>
        <Box sx={{alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", my: 2, }}>
          { isRegister ? (
            <>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle color="secondary" />
                    </InputAdornment>
                  ),
                }}
                label="First Name"
                onChange={(e) => {setFirstName(e.target.value)}}
                sx={{my: .5, outlineColor: "secondary", width: 300,}}
                value={firstName}
              />
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle color="secondary" />
                    </InputAdornment>
                  ),
                }}
                label="Last Name"
                onChange={(e) => {setLastName(e.target.value)}}
                sx={{my: .5, outlineColor: "secondary", width: 300,}}
                value={lastName}
              />
            </>
          ) : null}
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle color="secondary" />
                </InputAdornment>
              ),
            }}
            id="emailAddressTextField"
            label="Email Address"
            onChange={(e) => setEmailAddress(e.target.value)}
            sx={{my: .5, outlineColor: "secondary", width: 300,}}
            value={emailAddress}
          />
          { isRegister ? (
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle color="secondary" />
                  </InputAdornment>
                ),
              }}
              label="Confirm Email Address"
              onChange={(e) => {setConfirmEmailAddress(e.target.value)}}
              sx={{my: .5, outlineColor: "secondary", width: 300,}}
              value={confirmEmailAddress}
            />
          ) : null}
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock color="secondary" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            sx={{my: .5, outlineColor: "secondary", width: 300,}}
            type={showPassword ? "text" : "password"}
            value={password}
          />
          {
            isRegister ? (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="secondary" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{my: .5, outlineColor: "secondary", width: 300,}}
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
              />
            ) : null
          }
          <Button
            onClick={handleSubmit}
            sx={{my: .5, width: 300,}}
            variant="contained"
          >
            { !isRegister ? "Sign In" : "Sign Up" }
          </Button>
          <Button
            color="secondary"
            onClick={() => {setIsRegister(!isRegister)}}
            variant="contained"
            sx={{my: .5, width: 300,}}
          >
            {!isRegister ? "Register" : "Back to Sign In"}
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={action}
      />
    </Box>
  )
}

export default SignInOrRegister;