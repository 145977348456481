import React, { useEffect, } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import { sendEmailWithSES } from '../../api/sesAPI'
import { getAuth } from 'firebase/auth';

const EmailVertification = (props) => {

  // const [pending, setPending] = useState(false);
  const firstName = useSelector(state => state.app.firstName);
  const lastName = useSelector(state => state.app.lastName);


  useEffect(() => {

    const emailRecipient = "it@cadsoft.com";
    const emailSubject = "New User: Sto Beta";
    const emailBody =  `${firstName + " " + lastName + " just logged in to sto.cadsoft.com from " + getAuth().currentUser.email + ". This account has not yet been verified."}`
    sendEmailWithSES(emailRecipient, emailSubject, emailBody);

    return () => { console.log("Clean up for sending an email with SES.") };
  }, [])



  return (
    <Box sx={{display: "flex", flex: 1, flexDirection: "column",}}>
      <Box sx={{my: 2,}}>
        <Typography>This is a closed beta and your account has not yet been verified by the Cadsoft team.</Typography>
        {/* <Typography>Please confirm your information, then hit request verification.</Typography> */}
        <Typography>Verification can take up to one full business day.</Typography>
        <Typography>Please reach out to us by chat if you are experiencing any issues.</Typography>
      </Box>
      <Divider />
    </Box>
  )
}

export default EmailVertification