import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const DefaultTheme = createTheme({
  palette: {
    primary: {
      main: '#ffdb00',
    },
    secondary: {
      main: '#d13138',
    },
    tertiary: {
      main: "#000000",
    },
    info: {
      main: '#027eaa',
    },
    success: {
      main: '#05862d',
    },
  }
});

export default DefaultTheme;
