import { AppBar, Typography } from '@mui/material'
import React from 'react'

const Footer = (props) => {

  const currentDate = new Date;
  const currentYear = currentDate.getFullYear()

  return (
    <AppBar
      color="tertiary"
      sx={{ alignItems: "center", bottom: 0, display: "flex", height: 25, justifyContent: "center", top: "auto", }}
    >
      <Typography color="white" variant="h6" fontSize={12}>© {currentYear} All Rights Reserved. Powered by Cadsoft® Envisioneer</Typography>
    </AppBar>
  )
}

export default Footer