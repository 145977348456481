import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    currentlyReading: null,
    downloads: [],
    help: [],
    firstName: "",
    isAdmin: false,
    isAuthenticated: false,
    isEmailVerified: false,
    lastName: "",
    posts: [],
    read: [],
  },
  reducers: {
    updateCurrentlyReading: (state, action) => {
      state.currentlyReading = action.payload;
    },
    updateDownloads: (state, action) => {
      if (action.payload.length === 0) {
        state.downloads = [];
      } else {
        for (let i = 0; i < action.payload.length; i++) {
          if (!state.downloads.includes(action.payload[i])) {
            state.downloads = state.downloads.concat(action.payload[i]);
          }
        }
      }
    },
    updateFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    updateHelp: (state, action) => {
      if (action.payload.length === 0) {
        state.help = [];
      } else {
        for (let i = 0; i < action.payload.length; i++) {
          if (!state.help.includes(action.payload[i])) {
            state.help = state.help.concat(action.payload[i]);
          }
        }
      }
    },
    updateIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    updateIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    updateIsEmailVerified: (state, action) => {
      state.isEmailVerified = action.payload;
    },
    updateLastName: (state, action) => {
      state.lastName = action.payload;
    },
    updatePosts: (state, action) => {
      if (action.payload.length === 0) {
        state.posts = [];
      } else {
        for (let i = 0; i < action.payload.length; i++) {
          if (!state.posts.includes(action.payload[i])) {
            state.posts = state.posts.concat(action.payload[i]);
          }
        }
      }
    },
    updateRead: (state, action) => {
      state.read = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  updateCurrentlyReading,
  updateDownloads,
  updateFirstName,
  updateHelp,
  updateIsAdmin,
  updateIsAuthenticated,
  updateIsEmailVerified,
  updateLastName,
  updatePosts,
  updateRead,
} = appSlice.actions

export default appSlice.reducer